import styled from 'styled-components';
import Sale from './Sale';
import Rent from './Rent';
import Image from '../../Image';
import Divider from '../../Divider';

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1119px auto;
  grid-template-rows: 418px auto;
  grid-template-areas:
    'sale map'
    'rent map';
  column-gap: 16px;
  row-gap: 12px;
  width: 100%;
  padding: 0;
`;

const StyledSale = styled(Sale)`
  grid-area: sale;
`;

const StyledRent = styled(Rent)`
  grid-area: rent;
`;

const StyledMap = styled.div`
  grid-area: map;
`;

const Resume = ({ className, data }) => {
  const { map, mapCoordinates, table, resume } = data;
  const [latitude, longitude] = mapCoordinates || [];

  const generateMarker = (
    size = 's',
    icon = 'heart',
    color = 'ec1313',
    lng = -73.77,
    lat = 42.6564
  ) => {
    return 'pin-' + size + '-' + icon + '+' + color + `(${lng},${lat})`;
  };

  const generateMarkers = (markers = []) => {
    return markers.map(marker => {
      const [latitude, longitude] = marker?.coordinates.split(',');
      const color = marker?.operation === 'sale' ? '775175' : 'CC7D34';
      const icon = marker?.id + 1;
      return generateMarker('s', icon, color, longitude, latitude);
    });
  };

  const mapNormalStyle = 'udarealestate/ckoskrsre4iro17n4rr0qlsrq';

  //Central marker
  const centralMarker = `pin-l-circle+0C1B7A(${longitude},${latitude})`;

  //Mapbox
  const urlMap = `https://api.mapbox.com/styles/v1/${mapNormalStyle}/static/${centralMarker}${
    map.length && ',' + generateMarkers(map)
  }/${longitude},${latitude},14.3/460x727?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;

  return (
    <StyledWrapper className={className}>
      <Divider>Resume Comps Selected</Divider>
      <StyledContent>
        <StyledSale resume={resume?.sale} rows={table?.sale || []} />
        <StyledRent resume={resume?.rent} rows={table?.rent || []} />
        <StyledMap>
          {mapCoordinates && <Image src={urlMap} width="460px" height="727px" />}
        </StyledMap>
      </StyledContent>
    </StyledWrapper>
  );
};
export default Resume;
