import Location from "../Page1/Location/index";
import Footer from "../Page1/Footer/index";
import Detail from "../Page1/Detail/index";
import styled from "styled-components";

const StyledDevelopmentData = styled.div`
    display: grid;
    grid-template: "detail location" 916px "footer footer" 144px / auto auto;
    gap: 20px 14.5px;
    width: 100%;
    padding: 16px 22px;
`;
const StyledLocation = styled(Location)`
    grid-area: location;
    height: 100%;
`;

const StyledDetail = styled(Detail)`
    grid-area: detail;
`;
const StyledFooter = styled(Footer)`
    grid-area: footer;
`;

const Page1 = ({ data }) => {
    const { detail, location, footer } = data;
    return (
        <StyledDevelopmentData>
            <StyledDetail data={detail} />
            <StyledLocation data={location} />
            <StyledFooter data={footer} />
        </StyledDevelopmentData>
    );
};
export default Page1;
