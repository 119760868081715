import styled from 'styled-components';
import CustomDivider from '../CustomDivider';
import { useSaleRentTableConfig } from './useSaleRentTableConfig';
import AssetsTable from '../../AssetsTable';
import formatNumber from '../../utils/formatNumber';

const StyledWrapper = styled.div`
  width: 100%;
`;

const Sale = ({ className, rows, resume }) => {
  const { price, minPrice, area, maxArea, minArea, maxPrice, priceM2, maxPriceM2, minPriceM2 } =
    resume || {};
  const saleDivValues = [
    {
      title: 'Value',
      value: `${formatNumber(price)}€`,
      range: `${formatNumber(minPrice)} - ${formatNumber(maxPrice)}€`
    },
    {
      title: 'SQM Value',
      value: `${formatNumber(priceM2)}€/sqm`,
      range: `${formatNumber(minPriceM2)} - ${formatNumber(maxPriceM2)}€/sqm`
    },
    {
      title: 'Area',
      value: `${formatNumber(area)} sqm`,
      range: `${formatNumber(minArea)} - ${formatNumber(maxArea)} sqm`
    }
  ];

  const { columns } = useSaleRentTableConfig();

  return (
    <StyledWrapper className={className}>
      {resume && (
        <CustomDivider title={'Sale'} values={saleDivValues} divType={'Sale'}>
          <AssetsTable height="384px" width="1119px" rows={rows} columns={columns} />
        </CustomDivider>
      )}
    </StyledWrapper>
  );
};

export default Sale;
