import Tag from '../Tag/Tag';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;
const StyledWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`;
const StyledTag = styled(Tag)`
  position: absolute;
  left: 6px;
  bottom: 8px;
  font-size: 8px;
  width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
`;

const Image = ({ src, label, width, height }) => {
  return (
    <StyledWrapper style={{ width, height }}>
      <StyledImage src={src} alt="alt" />
      <StyledTag background="primary100" color="primary500" rounded>
        {label}
      </StyledTag>
    </StyledWrapper>
  );
};

export default Image;
