export default {
    pages: {
        DESKTOP_SHEET: "/desktop/sheet",
        ASSET_SHEET: "/asset/sheet",
    },
    api: {
        indicators: {
            LIST: "indicators/v2/list",
        },
        comparables: {
            LIST: "comparables/v2/list",
        },
    },
};
