import styled from 'styled-components';
import Divider from '../../../Divider';
import Table from '../../../Table';
import Leyend from './Leyend';
import { useAreasTableConfig } from './useAreasTableConfig';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
`;
const StyledLeyend = styled(Leyend)`
  padding-top: 56px;
`;

const StyledTable = styled(Table)`
  .MuiDataGrid-row {
    font-size: 8px !important;
  }
`;

const DevtUnitsAreas = ({ className, data }) => {
  const { areasAndUnits } = data;
  const { columns } = useAreasTableConfig();
  const items = [
    'Multi-family Housing',
    'Garage',
    'Storage',
    'Terraced House',
    'Detached House',
    'Commercial',
    'Office',
    'Industrial',
    'Other',
    'Building',
    'Land',
    'Subtotal'
  ];
  return (
    <div className={className}>
      <Divider>Development units</Divider>
      <StyledWrapper>
        <StyledLeyend items={items} />
        <StyledTable rowHeight={25} rows={areasAndUnits} columns={columns} title="Areas & Units" />
      </StyledWrapper>
    </div>
  );
};

export default DevtUnitsAreas;
