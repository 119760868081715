import styled from 'styled-components';
import { Label, BoxValue } from '../../../CommonStyledComponents';

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const StyledValues = styled.div`
  display: flex;
  gap: 2px;
`;

const Hospitality = ({ items }) => {
  return (
    <StyledWrapper>
      <StyledTitle>
        <Label color="#747D93">Hospitality</Label>
      </StyledTitle>
      <StyledContent>
        {items?.map(({ label, value }) => (
          <StyledItem>
            <Label color="#747D93">{label}</Label>
            <StyledValues>
              <BoxValue background="#FAFBFC" width="35px">
                {value || '-'}
              </BoxValue>
            </StyledValues>
          </StyledItem>
        ))}
      </StyledContent>
    </StyledWrapper>
  );
};

export default Hospitality;
