import styled from 'styled-components';
import {
  Label,
  BoxValue,
  LabelTitle
} from '../../../CommonStyledComponents';

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const StyledValues = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledLabelTitle = styled(LabelTitle)`
  display: flex;
  margin-top: 2px;
  gap: 2px;
`;
const StyledTags = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LandWip = ({ items }) => {
  return (
    <StyledWrapper>
      <StyledTitle>
        <Label color="#747D93">Land</Label>
        <StyledTags>
          <StyledLabelTitle color="#B6BBC6">Last dev status</StyledLabelTitle>
          <LabelTitle color="#B6BBC6">#Assets</LabelTitle>
        </StyledTags>
      </StyledTitle>
      <StyledContent>
        {(!items || items?.length === 0) && (
          <StyledItem>
            <Label color="#747D93">No data</Label>
            <StyledValues>
              <BoxValue background="#FAFBFC" width="45px">
                -
              </BoxValue>
              <BoxValue background="#FAFBFC" width="45px">
                -
              </BoxValue>
            </StyledValues>
          </StyledItem>
        )}
        {items?.map(({ label, value }) => (
          <StyledItem>
            <Label color="#747D93">{label}</Label>
            <StyledValues>
              <BoxValue background="#FAFBFC" width="45px">
                {value?.units || '-'}
              </BoxValue>
              <BoxValue background="#FAFBFC" width="45px">
                {value?.status || '-'}
              </BoxValue>
            </StyledValues>
          </StyledItem>
        ))}
      </StyledContent>
    </StyledWrapper>
  );
};

export default LandWip;
