export const stateColor = value => {
  let colors = {
    unpublished: {
      background: 'aubergine200',
      color: 'aubergine700'
    },
    sold: {
      background: 'carrot200',
      color: 'carrot700'
    },
    published: {
      background: 'forest200',
      color: 'forest700'
    }
  };

  let lowercaseValue = value && value.toLowerCase();
  return (
    colors[lowercaseValue] || {
      background: 'forest200',
      color: 'forest700'
    }
  );
};
