import styled from 'styled-components';
import Address from './Address';
import Risks from './Risks';
import AssetComments from './AssetComments';
import IdentificationFeatures from './IdentificationFeatures';
import LocationQuality from './LocationQuality';
import MainFeatures from './MainFeatures';
import Market from './Market';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #eff2f7;
  border-radius: 16px;
`;

const StyledAsset = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

const Detail = ({ className, data }) => {
  const {
    addressInfo,
    identificationFeatures,
    mainFeatures,
    locationQuality,
    risks,
    market,
    assetsComments
  } = data;
  return (
    <StyledWrapper className={className}>
      <StyledAsset>
        <Address data={addressInfo} />
        <IdentificationFeatures data={identificationFeatures} />
        <MainFeatures data={mainFeatures} />
        <AssetComments data={assetsComments} />
      </StyledAsset>
      <StyledContainer>
        <LocationQuality data={locationQuality} />
        <Risks data={risks} />
        <Market data={market} />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default Detail;
