export const development = {
  development: {
    inspection_type: "Desktop",
    registry_plot: "36310",
    cadastral_reference: "3756240UF7635N0008LI",
    urbanistic_charges: null,
    interior_state_of_repair: null,
    use: "Residential",
    use_id: 1,
    occupancy_status: null,
    year_built: 1960,
    gross_yield: null,
    market_value: null,
    built_market_value: null,
    land_market_value: null,
    project_name: "Demo Pulse Desktop",
    client_development_id: "Group_0026",
    lat: 36.72629702,
    lon: -4.41477831,
    address: "Calle Agustn Moreto",
    municipality: "MLAGA",
    zip_code: "29012",
    province: "MLAGA",
    assets_comments: "",
    macro_location: 5,
    micro_location1: null,
    location_comments: "Macro:\r\nMicro:",
    dev_companies: [
      {
        id: 240,
        name: "Catella",
      },
    ],
    demand: null,
    time_for_sale: null,
    valuation_method: null,
    offer: null,
    potential_buyer: null,
    valuation_comment: null,
    user_company: {
      id: 1,
      name: "uDA",
    },
    last_update: "2024-01-30T14:50:41.957098Z",
  },
  assets: [
    {
      building_quality: null,
      rooms: null,
      milestones_status: null,
      land_status: null,
      lift: null,
      pool: null,
      missing_fol: null,
      missing_cfo: false,
      stars: null,
      asset_id: "1534",
      id: "379122",
      is_anchor: true,
      registry: "MALAGA",
      registry_plot: "36310",
      use: "Residential",
      use_id: 1,
      typology: "Apartment",
      cadastral_reference: "3756240UF7635N0008LI",
      parking_annex: null,
      storage_annex: null,
      listing_url: null,
      listing_price: null,
      interior_state_of_repair: null,
      occupancy_status: null,
      floor: 1,
      bedrooms: null,
      seller_av_dt: 2256681.92,
      seller_av_dt_date: "2015-01-01",
      year_built: 1960,
      market_value: null,
      built_market_value: null,
      rent_value: null,
      land_valuation_area: null,
      built_valuation_area: null,
      current_rent: null,
      vpo: null,
      vpo_end: null,
      land_valuation_source: null,
      built_valuation_source: null,
      gross_yield: null,
      urbanistic_issues: null,
      conflictive_area: null,
      bricked_up: null,
      part_of_a_wip: null,
      town_hall_enquiry: null,
      status: "In progress",
      valuer: "demo@catella.com",
      liquidity: null,
    },
  ],
  comparables: [],
};

export const developmentProcessed = {
  header: {
    projectName: "Demo Pulse Desktop",
    valuationDate: "2024-01-30T14:50:41.957098Z",
    devId: "Group_0026",
    visitType: "Desktop",
  },
  page1: {
    detail: {
      addressInfo: {
        address: "Calle Agustn Moreto",
        municipality: "MLAGA",
        province: "29012 MLAGA",
        use: "residential",
        published: false,
      },
      identificationFeatures: {
        registryPlot: "36310",
        cadastre: "3756240UF7635N0008LI",
        occupancy: "-",
        yearBuilt: 1960,
        works: "-",
      },
      locationQuality: {
        rooms: {
          1: "",
          2: "",
          3: "",
          4: "",
          ">4": "",
        },
        land: [
          {
            land_status: null,
            milestones_status: null,
            units: 1,
          },
        ],
        hospitality: {
          rooms: null,
          stars: null,
        },
        other: {
          vandalized: "-",
          poor: "-",
          average: "-",
          good: "-",
        },
      },
      risks: {
        urbanistic_issues: false,
        conflictive_area: false,
        bricked_up: false,
        part_of_a_wip: false,
        vpo: false,
        end_current_rent: false,
        missing_fol: false,
        missing_cfo: false,
        town_hall_enquiry: false,
        start_current_rent: false,
        urbanistic_charges: null,
        rent_end_date: null,
        endCurrentRent: false,
        startCurrentRent: false,
      },
      market: {
        offer: null,
        demand: null,
        potentialBuyer: null,
        liquidity: null,
      },
      mainFeatures: {
        buildingQuality: null,
        lift: false,
        swimmingPool: false,
      },
      assetsComments: "",
    },
    location: {
      imageCoordinates: [36.72629702, -4.41477831],
      locationQuality: {
        microLocation: null,
        macroLocation: 5,
      },
      developmentUnits: {
        areasAndUnits: [
          {
            areaSum: 0,
            plotSum: 0,
            units: 1,
            unitsAnexed: 0,
            id: 1,
          },
          {
            id: 2,
          },
          {
            id: 3,
          },
          {
            id: 4,
          },
          {
            id: 5,
          },
          {
            id: 6,
          },
          {
            id: 7,
          },
          {
            id: 8,
          },
          {
            id: 9,
          },
          {
            id: 10,
          },
          {
            id: 11,
          },
          {
            areaSum: 0,
            plotSum: 0,
            units: 1,
            unitsAnexed: 0,
            id: 12,
          },
        ],
      },
      locationComments: "Macro:\r\nMicro:",
    },
    footer: {
      description: {
        comment: null,
      },
      valuationInfo: {
        valuationMethod: null,
        salesComparision: null,
        estimatedRent: null,
        erv: 0,
        implYield: "0.00",
      },
      marketValue: {
        marketValue: 0,
        sqm: null,
        sqmLand: null,
      },
    },
  },
  page2: {
    developmentUnits: {
      areasAndUnits: [
        {
          areaSum: 0,
          plotSum: 0,
          units: 1,
          unitsAnexed: 0,
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
        {
          id: 8,
        },
        {
          id: 9,
        },
        {
          id: 10,
        },
        {
          id: 11,
        },
        {
          areaSum: 0,
          plotSum: 0,
          units: 1,
          unitsAnexed: 0,
          id: 12,
        },
      ],
      marketComparables: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
        {
          id: 8,
        },
        {
          id: 9,
        },
        {
          id: 10,
        },
        {
          id: 11,
        },
        {
          asking: {
            rent: {
              count: 0,
              sqm_value: 0,
              average: 0,
            },
            sale: {
              count: 0,
              sqm_value: 0,
              average: 0,
            },
          },
          closing: {
            rent: {
              count: 0,
              sqm_value: 0,
              average: 0,
            },
            sale: {
              count: 0,
              sqm_value: 0,
              average: 0,
            },
          },
          id: 12,
        },
      ],
      adoptedValues: [
        {
          marketValue: 0,
          marketValueSqm: null,
          marketValueUnit: 0,
          estimatedRent: 0,
          rentUnit: 0,
          rentSqm: null,
          units: 1,
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
        {
          id: 8,
        },
        {
          id: 9,
        },
        {
          id: 10,
        },
        {
          id: 11,
        },
        {
          marketValue: 0,
          marketValueSqm: null,
          marketValueUnit: 0,
          estimatedRent: 0,
          rentUnit: 0,
          rentSqm: null,
          units: 1,
          id: 12,
        },
      ],
      sellersAppraisal: [
        {
          sellersAppraisal: 2256681.92,
          oldestDate: "2015-01-01",
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
        {
          id: 8,
        },
        {
          id: 9,
        },
        {
          id: 10,
        },
        {
          id: 11,
        },
        {
          sellersAppraisal: 2256681.92,
          oldestDate: "2015-01-01",
          id: 12,
        },
      ],
    },
    anchorProperties: {
      anchorData: [
        {
          listingType: "Residential",
          area: "-",
          built: 1960,
          floor: 1,
          price: "-",
          priceM2: "-",
          comparablesSheetData: [
            {
              list: [
                {
                  key: "min",
                  value: "-",
                  squareMeterValue: "-",
                },
                {
                  key: "max",
                  value: "-",
                  squareMeterValue: "-",
                },
                {
                  key: "AVG",
                  value: "-",
                  squareMeterValue: "-",
                },
              ],
              key: "Listing",
            },
            {
              list: [
                {
                  key: "min",
                  value: "-",
                  squareMeterValue: "-",
                },
                {
                  key: "max",
                  value: "-",
                  squareMeterValue: "-",
                },
                {
                  key: "AVG",
                  value: "-",
                  squareMeterValue: "-",
                },
              ],
              key: "Sold",
            },
            {
              list: [
                {
                  key: "min",
                  value: "-",
                  squareMeterValue: "-",
                },
                {
                  key: "max",
                  value: "-",
                  squareMeterValue: "-",
                },
                {
                  key: "AVG",
                  value: "-",
                  squareMeterValue: "-",
                },
              ],
              key: "Rent",
            },
          ],
        },
      ],
    },
    comparables: {
      resume: {
        sale: null,
        rent: null,
      },
      table: {
        sale: [],
        rent: [],
      },
      map: [],
      mapCoordinates: [36.72629702, -4.41477831],
    },
  },
  page3: {
    assets: [
      {
        id: 1,
        asset_id: "1534",
        registry_plot: "36310",
        cadastral_ref: "3756240UF7635N0008LI",
        use: "Residential",
        tipology: "Apartment",
        floor: 1,
        parking_anex: "-",
        storage_anex: "-",
        rooms: "-",
        repair_state: "-",
        current_rent: "-",
        area: "-",
        area_source: "-",
        plot_area: "-",
        plot_area_source: "-",
        market_value: "-",
        market_value_sqm: "-",
        estimated_rent: "-",
        rent: "-",
        sellers_appraisal: 2256681.92,
        sellers_app_date: "2015-01-01",
        listing_url: "-",
        listing_price: "-",
      },
    ],
  },
};
