import styled from 'styled-components';
import DevelopmentUnits from './DevelopmentUnits/index';
import Anchor from './Anchor/index';
import Resume from './Resume/index';

const StyledDevelopmentData = styled.div`
  display: grid;
  grid-template-columns: 1061px auto;
  grid-template-rows: 297px auto;
  grid-template-areas:
    'devUnits anchor'
    'resume resume';
  column-gap: 16px;
  row-gap: 12px;
  width: 100%;
  padding: 16px 32px 19px 32px;
`;

const StyledDevUnits = styled(DevelopmentUnits)`
  grid-area: devUnits;
`;

const StyledAnchor = styled(Anchor)`
  grid-area: anchor;
`;

const StyledResume = styled(Resume)`
  grid-area: resume;
`;

const Page2 = ({ data }) => {
  const { developmentUnits, anchorProperties, comparables } = data;
  return (
    <StyledDevelopmentData>
      <StyledDevUnits data={developmentUnits} />
      <StyledAnchor data={anchorProperties} />
      <StyledResume data={comparables} />
    </StyledDevelopmentData>
  );
};
export default Page2;
