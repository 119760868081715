import styled from 'styled-components';
import {
  Label,
  BoxValue,
  LabelTitle
} from '../../../CommonStyledComponents';

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const StyledValues = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledLabelTitle = styled(LabelTitle)`
  display: flex;
  margin-top: 2px;
  gap: 2px;
`;
const StyledTags = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SryledLabel = styled(Label)`
  text-transform: capitalize;
`;

const Other = ({ items }) => {
  return (
    <StyledWrapper>
      <StyledTitle>
        <Label color="#747D93">Other</Label>
        <StyledTags>
          <StyledLabelTitle color="#B6BBC6">Condition</StyledLabelTitle>
          <StyledLabelTitle color="#B6BBC6"># Assets</StyledLabelTitle>
        </StyledTags>
      </StyledTitle>
      <StyledContent>
        {items &&
          Object.entries(items)?.map(([label, value]) => (
            <StyledItem>
              <SryledLabel color="#747D93">{label}</SryledLabel>
              <StyledValues>
                <BoxValue background="#FAFBFC" width="35px">
                  {value}
                </BoxValue>
              </StyledValues>
            </StyledItem>
          ))}
      </StyledContent>
    </StyledWrapper>
  );
};

export default Other;
