import styled from 'styled-components';
import { useAssetsTableConfig } from './useAssetsTableConfig';
import AssetsTable from '../AssetsTable';
import Divider from '../Divider';
import Header from '../Header';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 32px 19px 32px;
`;
const StyledHeader = styled(Header)`
  width: 100%;
  margin-bottom: 8px;
  border-bottom: 1px solid #eff2f7;
`;
const StyledDivider = styled(Divider)`
  margin-bottom: 8px;
`;
const StyledIdentification = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;
const StyledIdentificationItem = styled.div`
  font-size: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid #eff2f7;
  text-align: center;
  width: ${({ width }) => width}px;
  text-transform: uppercase;
`;

const AssetsPages = ({ className, pageWidth, pageHeight, data, header, logo }) => {
  const { columns } = useAssetsTableConfig();

  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  return (
    <>
      {chunkArray(data?.assets, 41).map((rows, index) => (
        <div
          style={{
            height: `${pageHeight}px`,
            margin: 'auto',
            width: `${pageWidth}px`,
            position: 'relative',
            breakAfter: 'page'
          }}
        >
          <StyledHeader data={header} logo={logo} />
          <StyledWrapper className={className}>
            <StyledDivider>Single ID Breakdown</StyledDivider>
            <StyledIdentification>
              <StyledIdentificationItem width={410}>identification</StyledIdentificationItem>
              <StyledIdentificationItem width={410}>ASSET DETAILS</StyledIdentificationItem>
              <StyledIdentificationItem width={261}>AREAS</StyledIdentificationItem>
              <StyledIdentificationItem width={401}>values</StyledIdentificationItem>
              <StyledIdentificationItem width={86}>PREVIOUS LISTING</StyledIdentificationItem>
            </StyledIdentification>
            <AssetsTable height="100%" width="1590px" rows={rows} columns={columns} />
          </StyledWrapper>
        </div>
      ))}
    </>
  );
};

export default AssetsPages;
