import Sheet from "./components/PromotionSheet";
import GlobalStyles from "@uda/bento-components/lib/themes/global";
import { developmentProcessed } from "./mock/development";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { IntlProvider, useIntl } from "react-intl";
import { ThemeProvider } from "styled-components";
import { useState } from "react";
import defaultTheme from "./themes/defaultTheme";
import routes from "./config/routes";

function App() {
    const width = 1654;
    const height = 1170;
    const developmentData = developmentProcessed;
    const [theme, setTheme] = useState(defaultTheme);
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <BrowserRouter>
                    <Routes>
                        <Route
                            path={routes.pages.DESKTOP_SHEET}
                            element={
                                <Sheet
                                    pageWidth={width}
                                    pageHeight={height - 1}
                                    inputData={developmentData}
                                />
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
