import React from 'react';
import { GridRow } from '@mui/x-data-grid';
import styled from 'styled-components';
import formatNumber from '../utils/formatNumber';

const StyledHeaderText = styled.span`
  font-size: 8px;
  line-height: 8px;
  font-weight: 700;
  text-align: center;
`;
const StyledHeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const useAssetsTableConfig = () => {
  const columns = [
    {
      field: 'id',
      headerName: '',
      width: 18,
      maxWidth: 18,
      minWidth: 18,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.id}</>
    },
    {
      field: 'asset_id',
      headerName: 'Asset ID',
      renderHeader: () => <StyledHeaderText>Asset ID</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.asset_id}</>
    },
    {
      field: 'registry_plot',
      headerName: 'Registry Plot',
      renderHeader: () => <StyledHeaderText>Registry Plot</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.registry_plot}</>
    },
    {
      field: 'cadastral_ref',
      headerName: 'Cadastral Ref',
      renderHeader: () => <StyledHeaderText>Cadastral Ref</StyledHeaderText>,
      width: 110,
      maxWidth: 110,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.cadastral_ref}</>
    },
    {
      field: 'use',
      headerName: 'Use',
      renderHeader: () => <StyledHeaderText>Use</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.use}</>
    },
    {
      field: 'tipology',
      headerName: 'Typology',
      renderHeader: () => <StyledHeaderText>Typology</StyledHeaderText>,
      width: 73,
      maxWidth: 73,
      minWidth: 73,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.tipology}</>
    },
    {
      field: 'floor',
      headerName: 'Floor',
      renderHeader: () => <StyledHeaderText>Floor</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.floor}</>
    },
    {
      field: 'parking_anex',
      headerName: 'Parking Annexes',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Parking</StyledHeaderText>
          <StyledHeaderText>Annexes</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.parking_anex}</>
    },
    {
      field: 'storage_anex',
      headerName: 'Storage Annexes',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Storage</StyledHeaderText>
          <StyledHeaderText>Annexes</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.storage_anex}</>
    },
    {
      field: 'rooms',
      headerName: 'Bedrooms',
      renderHeader: () => <StyledHeaderText>Bedrooms</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.rooms}</>
    },
    {
      field: 'repair_state',
      headerName: 'Interior State of Reparir',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Interior State</StyledHeaderText>
          <StyledHeaderText>of Reparir</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.repair_state}</>
    },
    {
      field: 'current_rent',
      headerName: 'Current rent (€)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Current rent</StyledHeaderText>
          <StyledHeaderText>(€)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.current_rent)}</>
    },
    {
      field: 'area',
      headerName: 'Area (sqm)',
      renderHeader: () => <StyledHeaderText>Area (sqm)</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.area}</>
    },
    {
      field: 'area_source',
      headerName: 'Area Source',
      renderHeader: () => <StyledHeaderText>Area Source</StyledHeaderText>,
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.area_source}</>
    },
    {
      field: 'plot_area',
      headerName: 'Plot Area (sqm)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Plot Area</StyledHeaderText>
          <StyledHeaderText>(sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.plot_area}</>
    },
    {
      field: 'plot_area_source',
      headerName: 'Plot Area Source',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Plot Area</StyledHeaderText>
          <StyledHeaderText>Source</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.plot_area_source}</>
    },
    {
      field: 'market_value',
      headerName: 'Market Value (€)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Market Value</StyledHeaderText>
          <StyledHeaderText>(€)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.market_value)}</>
    },
    {
      field: 'market_value',
      headerName: 'Market Value (€/sqm)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Market Value</StyledHeaderText>
          <StyledHeaderText>(€/sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.market_value_sqm)}</>
    },
    {
      field: 'estimated_rent',
      headerName: 'Estimated Rent (€/mo)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Estimated</StyledHeaderText>
          <StyledHeaderText>Rent (€/mo)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.estimated_rent)}</>
    },
    {
      field: 'rent',
      headerName: 'Rent (€/mo/sqm)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Rent (€/mo/</StyledHeaderText>
          <StyledHeaderText>sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.rent)}</>
    },
    {
      field: 'sellers_appraisal',
      headerName: 'Seller’s Appraisal (€)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Seller’s</StyledHeaderText>
          <StyledHeaderText>Appraisal (€)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 68,
      maxWidth: 68,
      minWidth: 68,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.sellers_appraisal)}</>
    },
    {
      field: 'sellers_app_date',
      headerName: 'Seller’s App Date',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Seller’s</StyledHeaderText>
          <StyledHeaderText>App Date</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 41,
      maxWidth: 41,
      minWidth: 41,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{row.sellers_app_date}</>
    },
    {
      field: 'listing_url',
      headerName: 'Listing url',
      renderHeader: () => <StyledHeaderText>Listing url</StyledHeaderText>,
      width: 80,
      maxWidth: 80,
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <a href={row.listing_url}>{row.listing_url}</a>
    },
    {
      field: 'listing_price',
      headerName: 'Listing Price',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Listing</StyledHeaderText>
          <StyledHeaderText>Price</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 42,
      maxWidth: 42,
      minWidth: 42,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <>{formatNumber(row.listing_price)}</>
    }
  ];

  const memoizedRow = React.memo(GridRow);

  return { columns, memoizedRow };
};
