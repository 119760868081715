import styled from 'styled-components';
import { Label, LabelTitle } from '../../CommonStyledComponents';
import { formatCadastreLink } from '../../../../helpers/locations';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const StyledItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.a`
  font-size: 8px;
  height: 12px;
  line-height: 8px;
  color: #1778fb;
  font-weight: 700;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;

const IdentificationFeatures = ({ data }) => {
  const { registryPlot, cadastre, occupancy, yearBuilt, works } = data;
  return (
    <StyledWrapper>
      <StyledItem>
        <Label color="#747D93">Registry plot</Label>
        <LabelTitle>{registryPlot}</LabelTitle>
      </StyledItem>
      <StyledItem>
        <Label color="#747D93">Cadastre</Label>
        {cadastre ? (
          <StyledLink href={formatCadastreLink(cadastre)}>{cadastre}</StyledLink>
        ) : (
          <LabelTitle>-</LabelTitle>
        )}
      </StyledItem>
      <StyledItem>
        <Label color="#747D93">Occupancy</Label>
        <LabelTitle>{occupancy}</LabelTitle>
      </StyledItem>
      <StyledItem>
        <Label color="#747D93">Year built</Label>
        <LabelTitle>{yearBuilt}</LabelTitle>
      </StyledItem>
      <StyledItem>
        <Label color="#747D93">Works (%)</Label>
        <LabelTitle>{works}</LabelTitle>
      </StyledItem>
    </StyledWrapper>
  );
};

export default IdentificationFeatures;
