import Tag from '../../../Tag/Tag';
import styled from 'styled-components';
import { BoxValue, Label } from '../../CommonStyledComponents';
import Divider from '../../Divider';
import { tagColor } from '../../utils/tagColor';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  height: 71px;
  margin-top: 12px;
`;
const StyledContent = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledLiquidity = styled.div`
  width: 61px;
  border-radius: 8px;
  padding: 13.5px 10px;
  background-color: #fef9e7;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;
const StyledValue = styled.span`
  font-size: 24px;
  line-height: 28px;
  color: #af8904;
  text-transform: capitalize;
`;
const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTag = styled(Tag)`
  font-size: 8px;
  width: fit-content;
`;

const Market = ({ data }) => {
  const { offer, demand, potentialBuyer, liquidity } = data;
  return (
    <div>
      <Divider>Market</Divider>
      <StyledWrapper>
        <StyledContent>
          <StyledItem>
            <Label color="#747D93">Offer</Label>
            <StyledTag
              color={tagColor(offer)?.color}
              background={tagColor(offer)?.background}
              rounded
            >
              {offer}
            </StyledTag>
          </StyledItem>
          <StyledItem>
            <Label color="#747D93">Demand</Label>
            <StyledTag
              color={tagColor(demand)?.color}
              background={tagColor(demand)?.background}
              rounded
            >
              {demand}
            </StyledTag>
          </StyledItem>
          <StyledItem>
            <Label color="#747D93">Potential Buyer</Label>
            <BoxValue width="102px" background="#FAFBFC">
              {potentialBuyer || '-'}
            </BoxValue>
          </StyledItem>
        </StyledContent>
        <StyledLiquidity>
          <Label color="#AF8904">Liquidity</Label>
          <StyledValue>{liquidity}</StyledValue>
        </StyledLiquidity>
      </StyledWrapper>
    </div>
  );
};

export default Market;
