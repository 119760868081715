import React from 'react';
import { GridRow } from '@mui/x-data-grid';
import styled from 'styled-components';
import formatNumber from '../../utils/formatNumber';

const StyledHeaderText = styled.span`
  font-size: 8px;
  line-height: 8px;
  font-weight: 700;
  text-align: center;
`;
const StyledHeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const useMarketTableConfig = () => {
  const columns = [
    {
      field: 'asking_sale',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Asking Sale</StyledHeaderText>
          <StyledHeaderText>(€/sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 47.5,
      maxWidth: 47.5,
      minWidth: 47.5,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.asking?.sale?.average ? <>{formatNumber(row.asking.sale.average)}</> : <>-</>;
      }
    },
    {
      field: 'closing_sale',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Closing Sale</StyledHeaderText>
          <StyledHeaderText>(€/sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 47.5,
      maxWidth: 47.5,
      minWidth: 47.5,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.closing?.sale?.average ? <>{formatNumber(row.closing.sale.average)}</> : <>-</>;
      }
    },
    {
      field: 'asking_rent',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Asking Rent</StyledHeaderText>
          <StyledHeaderText>(€/sqm/mo)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 47.5,
      maxWidth: 47.5,
      minWidth: 47.5,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.asking?.rent?.average ? <>{formatNumber(row.asking.rent.average)}</> : <>-</>;
      }
    }
  ];

  const memoizedRow = React.memo(GridRow);

  return { columns, memoizedRow };
};
