import styled from 'styled-components';
import { Label, Comments } from '../../CommonStyledComponents';
import { IconInfo } from '@uda/bento-components';

const StyledWrapper = styled.div`
  width: 100%;
`;
const StyledComments = styled(Comments)`
  width: 705px;
  height: 148px;
`;

const LocationComments = ({ children, data }) => {
  return (
    <StyledWrapper>
      <Label>
        <IconInfo size="small" />
        Location Comments
      </Label>
      <StyledComments>{data || 'No comments'}</StyledComments>
    </StyledWrapper>
  );
};

export default LocationComments;
