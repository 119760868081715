import styled from 'styled-components';
import { Label } from '../../CommonStyledComponents';
import formatNumber from '../../utils/formatNumber';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 237px;
  font-size: 9px;
  margin-top: 4px;
`;

const StyledColumns = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
`;
const StyledColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const StyledDivider = styled.div`
  width: 100%;
  border: 1px solid #eff7fb;
  margin: 10px 0;
`;
const StyledLabel = styled(Label)`
  font-size: 10px;
`;

const ValuationInfo = ({ data }) => {
  const { valuationMethod, salesComparision, estimatedRent, implYield, erv } = data;
  return (
    <StyledWrapper>
      <StyledColumnsWrapper>
        <StyledColumns>
          <StyledLabel color="#dcdfe6">Valuation method</StyledLabel>
          {valuationMethod || '-'}
        </StyledColumns>
        <StyledColumns>
          <StyledLabel color="#dcdfe6">Sales comparison</StyledLabel>
          {salesComparision || '-'}
        </StyledColumns>
      </StyledColumnsWrapper>
      <StyledDivider></StyledDivider>
      <StyledRow>
        <StyledLabel color="#dcdfe6">Estimated rent</StyledLabel>
        {formatNumber(estimatedRent, 0) + '€' || '-'}
      </StyledRow>
      <StyledRow>
        <StyledLabel color="#dcdfe6">€ ERV</StyledLabel>
        {formatNumber(erv) || '-'}
      </StyledRow>
      <StyledRow>
        <StyledLabel color="#dcdfe6">Impl. Yield</StyledLabel>
        {implYield ? implYield + '%' : '-'}
      </StyledRow>
    </StyledWrapper>
  );
};

export default ValuationInfo;
