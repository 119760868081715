import styled from 'styled-components';
import Rooms from './Rooms';
import LandWip from './LandWip';
import Hospitality from './Hospitality';
import Other from './Other';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const StyledDivider = styled.div`
  background-color: #fafbfc;
  height: 1px;
  width: 100%;
`;

const LocationQuality = ({ children, data }) => {
  const { rooms, land: lands, hospitality, other } = data;

  const landWipItems = lands.map(land => ({
    label: land.land_status,
    value: {
      units: land?.units,
      status: land?.milestones_status
    }
  }));

  const hospitalityItems = [
    { label: '# Rooms', value: hospitality?.rooms },
    { label: '# STARS', value: hospitality?.stars }
  ];
  return (
    <StyledWrapper>
      <Rooms items={rooms} />
      <StyledDivider />
      <LandWip items={landWipItems} />
      <StyledDivider />
      <Hospitality items={hospitalityItems} />
      <StyledDivider />
      <Other items={other} />
    </StyledWrapper>
  );
};

export default LocationQuality;
