import styled from 'styled-components';
import Description from './Description';
import ValuationInfo from './ValuationInfo';
import MarketValue from './MarketValue';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #009fd6;
  color: #fff;
  padding: 14px 24px;
  border-radius: 16px;
  width: 100%;
  height: 100%;
`;

const Footer = ({ children, className, data }) => {
  const { description, marketValue, valuationInfo } = data;
  return (
    <StyledWrapper className={className}>
      <Description data={description} />
      <ValuationInfo data={valuationInfo} />
      <MarketValue data={marketValue} />
    </StyledWrapper>
  );
};

export default Footer;
