import styled, { css } from 'styled-components';
import { BoxValue, Label, LabelTitle } from '../../CommonStyledComponents';
import Divider from '../../Divider';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: -25px;
`;
const StyledBooleanWrapper = styled.div`
  display: flex;
  gap: 2px;
`;
const StyledBooleanOption = styled.div`
  ${({ active }) => css`
    background-color: ${active ? '#E4FAFF' : 'transparent'};
    border: 1px solid ${active ? '#E4FAFF' : '#DCDFE6'};
    color: ${active ? '#0077B6' : '#747D93'};
    display: flex;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    width: 33px;
    height: 20px;
    border-radius: 16px;
    font-size: 10px;
    line-height: 0px;
  `}
`;
const StyledItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 142.67px;
  gap: 8px;
`;
const StyledLabel = styled(Label)`
  max-width: 70px;
`;

const Boolean = ({ value }) => {
  return (
    <StyledBooleanWrapper>
      <StyledBooleanOption active={value}>yes</StyledBooleanOption>
      <StyledBooleanOption active={!value}>no</StyledBooleanOption>
    </StyledBooleanWrapper>
  );
};
const Column = ({ title = '', items }) => {
  const isBoolean = value => typeof value === 'boolean';
  return (
    <StyledItems>
      <LabelTitle>{title}</LabelTitle>
      {items?.map(({ label, value }) => (
        <StyledItem>
          <StyledLabel color="#747D93">{label}</StyledLabel>
          {isBoolean(value) ? (
            <Boolean value={value} />
          ) : (
            <BoxValue width="64.67px" background="#FAFBFC">
              {value || '-'}
            </BoxValue>
          )}
        </StyledItem>
      ))}
    </StyledItems>
  );
};
const Risks = ({ data }) => {
  const {
    urbanistic_issues,
    conflictive_area,
    bricked_up,
    part_of_a_wip,
    vpo,
    vpo_end,
    start_current_rent,
    end_current_rent,
    town_hall_enquiry,
    urbanistic_charges,
    missing_fol,
    missing_cfo,
    rent_end_date,
    endCurrentRent,
    startCurrentRent
  } = data;

  const environments = [
    { label: 'Urban. Issues', value: urbanistic_issues },
    { label: 'Conflict. Area', value: conflictive_area },
    { label: 'Bricked up', value: bricked_up },
    { label: 'Part of wip', value: part_of_a_wip }
  ];
  const assets = [
    { label: 'VPO', value: vpo },
    { label: 'VPO End date', value: vpo_end },
    { label: 'Rented', value: startCurrentRent },
    { label: 'Current Rent end', value: endCurrentRent },
    { label: 'Rent End date', value: rent_end_date },
  ];
  const specialEnquires = [
    { label: 'Town Hall', value: town_hall_enquiry },
    { label: 'Urbanistic char.', value: urbanistic_charges },
    { label: 'Miss FOL', value: missing_fol },
    { label: 'Miss CFO', value: missing_cfo }
  ];
  return (
    <div>
      <Divider>Risks</Divider>
      <StyledWrapper>
        <Column title="Environment" items={environments} />
        <Column title="Assets" items={assets} />
        <Column title="SpeciAL Enquiries" items={specialEnquires} />
      </StyledWrapper>
    </div>
  );
};

export default Risks;
