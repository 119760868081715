import React from 'react';
import { GridRow } from '@mui/x-data-grid';
import styled from 'styled-components';
import formatNumber from '../../utils/formatNumber';

const StyledHeaderText = styled.span`
  font-size: 8px;
  line-height: 8px;
  font-weight: 700;
  text-align: center;
`;
const StyledHeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const useSellersTableConfig = () => {
  const columns = [
    {
      field: 'seller-appraisal',
      headerName: 'Seller’s Appraisal (€)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Seller’s</StyledHeaderText>
          <StyledHeaderText>Appraisal (€)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 72,
      maxWidth: 72,
      minWidth: 72,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.sellersAppraisal ? <>{formatNumber(row.sellersAppraisal)}</> : <>-</>;
      }
    },
    {
      field: 'oldest-appraisal-date',
      headerName: 'Oldest App. Date',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Oldest</StyledHeaderText>
          <StyledHeaderText>App. Date</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 72,
      maxWidth: 72,
      minWidth: 72,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.oldestDate ? <>{row.oldestDate}</> : <>-</>;
      }
    }
  ];

  const memoizedRow = React.memo(GridRow);

  return { columns, memoizedRow };
};
