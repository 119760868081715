import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import qs from "qs"; // Assuming you're using qs to parse query strings, install with `npm install qs` if not already included
import Header from "./Header";
import Page1 from "./Page1";
import Page2 from "./Page2";
import AssetsPages from "./AssetsPages";
import styled from "styled-components";

import { mapDevelopment } from "./utils/adapter";

const StyledHeader = styled(Header)`
  width: 100%;
  margin-bottom: 8px;
  border-bottom: 1px solid #eff2f7;
`;

const PromotionSheet = memo(
  ({ inputData, logo, pageWidth = 1654, pageHeight = 1169}) => {

  const [developmentData, setDevelopmentData] = useState();
  const location = useLocation(); // Use the useLocation hook to access the location object

  // Parse the search string to get query parameters
  // location.search includes the leading ?, so you pass it directly into a query string parser
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {

    let id = queryParams?.id || null;
    let key = queryParams?.key || null;

    const requestURL = `${process.env.REACT_APP_API_URI}/properties/pulse/developments/${id}/info`

    if (id) {

      const myHeaders = new Headers();
      myHeaders.append("accept", "*/*");
      myHeaders.append("accept-language", "en-US,en;q=0.9,es;q=0.8");
      myHeaders.append("authorization", key);
      myHeaders.append("cache-control", "no-cache");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(requestURL, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();  // Parses the JSON from the response
      })
      .then(result => {
        // console.log(result);  // Logs the result to the console
        const mapping = mapDevelopment(result)
        console.log(mapping)
        setDevelopmentData(mapping);
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      });

    } else {
      setDevelopmentData(inputData)
    }
  }, [location.search]); // Ensure the effect runs whenever the search string changes

  if (!developmentData) return (<></>)

  return (

      <>
        <div
          style={{
            height: `${pageHeight}px`,
            margin: 'auto',
            width: `${pageWidth}px`,
            position: 'relative',
            breakAfter: 'page'
          }}
        >
          <StyledHeader data={developmentData?.header} logo={logo} />
          <Page1 data={developmentData?.page1} />
        </div>
        <div
          style={{
            height: `${pageHeight}px`,
            margin: 'auto',
            width: `${pageWidth}px`,
            position: 'relative',
            breakAfter: 'page'
          }}
        >
          <StyledHeader data={developmentData?.header} logo={logo} />
          <Page2 data={developmentData?.page2} />
        </div>
        <AssetsPages
          data={developmentData?.page3}
          header={developmentData?.header}
          pageHeight={pageHeight}
          pageWidth={pageWidth}
          logo={logo}
        />
      </>

    );
  }
);
export default PromotionSheet;
