import styled from 'styled-components';
import { Label, Comments } from '../../CommonStyledComponents';
import { IconInfo } from '@uda/bento-components';

const StyledWrapper = styled.div`
  width: 100%;
`;
const StyledComments = styled(Comments)`
  width: 100%;
  height: 100px;
  background-color: #fafbfc;
  margin-top: 6px;
`;

const AssetComments = ({ children, data }) => {
  const truncatedData = data?.length > 916 ? `${data.substring(0, 916)} ...` : data;
  return (
    <StyledWrapper>
      <Label color="#747D93">
        <IconInfo size="small" />
        Asset Comments
      </Label>
      <StyledComments>{truncatedData || 'No comments'}</StyledComments>
    </StyledWrapper>
  );
};

export default AssetComments;
