import styled, { css } from "styled-components";
import { Label } from "../../CommonStyledComponents";
import Tag from "../../../Tag/Tag";

const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    ${({ right }) =>
        right &&
        css`
            align-items: flex-end;
        `}
`;
const StyledLabel = styled(Label)`
    font-size: 12px;
`;
const StyledTitle = styled.h2`
    font-size: 16px;
    text-transform: capitalize;
`;
const StyledTag = styled(Tag)`
    font-size: 8px;
    width: fit-content;
    text-align: center;
    display: flex;
    align-items: center;
`;

const Address = ({ data }) => {
    const { address, municipality, province, use, published } = data;
    return (
        <StyledWrapper>
            <StyledContentWrapper>
                <Label color="#747D93">Address</Label>
                <StyledTitle>{address},</StyledTitle>
                <p>
                    <StyledLabel color="#363C4B">{municipality},</StyledLabel>{" "}
                    <StyledLabel color="#747D93">{province}</StyledLabel>
                </p>
            </StyledContentWrapper>
            <StyledContentWrapper right>
                <Label color="#747D93">Main use</Label>
                <StyledTitle>{use}</StyledTitle>
                {published && (
                    <StyledTag
                        background="forest100"
                        color="forest700"
                        rounded={true}
                    >
                        Published
                    </StyledTag>
                )}
            </StyledContentWrapper>
        </StyledWrapper>
    );
};

export default Address;
