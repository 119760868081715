import React from 'react';
import { GridRow } from '@mui/x-data-grid';
import styled from 'styled-components';
import formatNumber from '../../utils/formatNumber';

const StyledHeaderText = styled.span`
  font-size: 8px;
  line-height: 8px;
  font-weight: 700;
  text-align: center;
`;
const StyledHeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const useAdoptedValuesTableConfig = () => {
  const columns = [
    {
      field: 'market_value',
      headerName: 'Market Value',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Market Value</StyledHeaderText>
          <StyledHeaderText>(€)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.marketValue ? <>{formatNumber(row.marketValue)}</> : <>-</>;
      }
    },
    {
      field: 'market_value_sqm',
      headerName: 'Market Value sqm',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Market Value</StyledHeaderText>
          <StyledHeaderText>(€/sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.marketValueSqm ? <>{formatNumber(row.marketValueSqm)}</> : <>-</>;
      }
    },
    {
      field: 'market_value_unit',
      headerName: 'Market Value unit',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Market Value</StyledHeaderText>
          <StyledHeaderText>(€/unit)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.marketValueUnit ? <>{formatNumber(row.marketValueUnit)}</> : <>-</>;
      }
    },
    {
      field: 'avg_sqm_unit',
      headerName: 'Estima- Rent (€/mo)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Estima- Rent</StyledHeaderText>
          <StyledHeaderText>(€/mo)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.estimatedRent ? <>{formatNumber(row.estimatedRent)}</> : <>-</>;
      }
    },
    {
      field: 'rent_sqm',
      headerName: 'Rent (€/mo/sqm)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Rent</StyledHeaderText>
          <StyledHeaderText>(€/mo/sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.rentSqm ? <>{formatNumber(row.rentSqm)}</> : <>-</>;
      }
    },
    {
      field: 'rent_unit',
      headerName: 'Rent(€/mo/unit)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Rent</StyledHeaderText>
          <StyledHeaderText>(€/mo/unit)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.rentUnit ? <>{formatNumber(row.rentUnit)}</> : <>-</>;
      }
    }
  ];

  const memoizedRow = React.memo(GridRow);

  return { columns, memoizedRow };
};
