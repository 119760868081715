import styled, { useTheme } from 'styled-components';
import formatNumber from '../../utils/formatNumber';

const StyledComparablesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 32%;
  font-size: 7px;
  padding: 5px;
  border-radius: 8px;
  background: #BCDBD9;
`;
const StyledComparablesTitle = styled.span`
  font-size: 7px;
  color: green;
  font-weight: 700;
`;
const StyledComparablesDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3px;
`;
const StyledComparablesValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
`;
const StyledComparableValue = styled.label`
  font-size: 7px;
`;
const StyledComparableBoldValue = styled.label`
  font-size: 7px;
  font-weight: 700;
`;

const ComparableItem = ({comparables}) => {
    const theme = useTheme();
    const backgroundColor = comparables.key === 'Listing' ? theme.color.forest200 : comparables.key === 'Sold' ? theme.color.aubergine200 : theme.color.carrot200;
    const color = comparables.key === 'Listing' ? theme.color.forest700 : comparables.key === 'Sold' ? theme.color.aubergine700 : theme.color.carrot700;
    return (
        <StyledComparablesItem style={{backgroundColor: backgroundColor}}>
            <StyledComparablesTitle style={{color: color}}>{comparables.key}</StyledComparablesTitle>
            <StyledComparablesDataContainer>
                {comparables.list.map((comp) => (
                    <StyledComparablesValuesContainer>
                    <StyledComparableValue>{comp.key}</StyledComparableValue>
                    <StyledComparableBoldValue>{formatNumber(comp.value)} €</StyledComparableBoldValue>
                    <StyledComparableValue>{formatNumber(comp.squareMeterValue)} €/SQM</StyledComparableValue>
                    </StyledComparablesValuesContainer>
                ))}
            </StyledComparablesDataContainer>
        </StyledComparablesItem>
    )
}

export default ComparableItem;