export const tagColor = value => {
  let colors = {
    low: {
      background: 'golden200',
      color: 'golden900'
    },
    medium: {
      background: 'carrot200',
      color: 'carrot800'
    },
    high: {
      background: 'forest100',
      color: 'forest700'
    }
  };

  let lowercaseValue = value && value.toLowerCase();
  return (
    colors[lowercaseValue] || {
      background: 'forest100',
      color: 'forest700'
    }
  );
};
