import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const StyledItem = styled.span`
  font-size: 8px;
  text-transform: uppercase;
  color: #747d93;
`;
const Leyend = ({ items, className }) => {
  return (
    <StyledWrapper className={className}>
      {items?.map(i => (
        <StyledItem>{i}</StyledItem>
      ))}
    </StyledWrapper>
  );
};

export default Leyend;
