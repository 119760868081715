import styled from "styled-components";
import React, { useEffect, useState } from "react";
import portfolioLogo from "../../assets/images/logo-portfolio.svg";
import { FormattedDate } from "react-intl";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  height: 60px;
  width: 100%;
  padding: 0 32px;
  border-bottom: 1px solid #eff2f7;
  background-color: #eff2f7;
`;

const StyledProjectName = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: #0077b6;
`;
const StyledData = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #747d93;
  span {
    color: #0077b6;
  }
`;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const Header = ({ data, src, width, height, logo = portfolioLogo }) => {
  const [url, setUrl] = useState();

  useEffect(() => {
    setUrl(window.location.href);
  });

  return (
    <StyledWrapper style={{ width, height }}>
      <a target="_blank" href={url}>
        <img style={{ width: "auto", height: "27px" }} src={logo} alt="logo" />
      </a>
      <StyledProjectName>{data?.projectName}</StyledProjectName>
      <StyledData>
        Valuation date <span>{formatDate(data?.valuationDate)}</span>
      </StyledData>
      <StyledData>
        DEV id <span>{data?.devId}</span>
      </StyledData>
      <StyledData>
        Visit type <span>{data?.visitType}</span>
      </StyledData>
    </StyledWrapper>
  );
};

export default Header;
