import styled from 'styled-components';
import Divider from '../../Divider';
import { LabelTitle } from '../../CommonStyledComponents';
import { IconAnchor } from '@uda/bento-components';
import formatNumber from '../../utils/formatNumber';
import ComparableItem from './cardList';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  width:100%;
  heigth: 100%
`;
const StyledAnchorList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  heigth: 100%
`;
const StyledAnchorItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
  background-color: #eff2f7;
  border-radius: 8px;
  width: 100%;
  height: 90px;
  padding: 5px;
  margin-bottom: 5px;
`;
const StyledAnchorHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 5px;
`;
const StyledAnchorHeaderTitle = styled.label`
  font-size: 8px;
  font-weight: 700;
  margin-right: 10px;
`;
const StyledContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  height: 100%;
`;
const StyledContentItem = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3px;
`;
const StyledDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #dcdfe6;
`;
const StyledSectionDivider = styled(Divider)`
  margin-bottom: 10px;
`;
const StyledIconAnchor = styled(IconAnchor)`
  width: 12px;
  margin-rigth: 2px;
`;
const StyledItemTitle = styled.label`
  font-size: 7px;
  text-transform: uppercase;
  color: #B6BBC6;
  font-weight: 500;
`;
const StyledItemValue = styled.label`
  font-size: 7px;
  font-weight: 700;
`;
const StyledComparablesList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 100%;
  heigth: 100%;
`;

const AnchorItem = ({ items, listing_type }) => {
  return (
    <StyledAnchorItem>
      <StyledAnchorHeader>
        <LabelTitle>
          <StyledIconAnchor size="small" />
          <StyledAnchorHeaderTitle>{listing_type}</StyledAnchorHeaderTitle>
        </LabelTitle>
        {items.headerValues.map(({ label, value }, i) => (
          <StyledContent>
            <StyledContentItem>
              <StyledItemTitle>{label}</StyledItemTitle>
              <StyledItemValue>{value}</StyledItemValue>
            </StyledContentItem>
            {i !== items.headerValues.length - 1 && <StyledDivider />}
          </StyledContent>
        ))}
      </StyledAnchorHeader>
      <StyledComparablesList>
      {items.comparables.map((compList) => (
        <ComparableItem comparables={compList}></ComparableItem>
      ))}
      </StyledComparablesList>
    </StyledAnchorItem>
  );
};

const Anchor = ({ className, data }) => {
  const items = item => ({ headerValues : [
    { label: 'AREA (SQM)', value: item?.area, },
    { label: 'BUILT', value: item?.built },
    { label: 'floor', value: item?.floor },
    { label: '€', value: formatNumber(item?.price) },
    { label: '€/SQM', value: formatNumber(item?.priceM2) },
    ],
    comparables: item?.comparablesSheetData});
  return (
    <StyledWrapper className={className}>
      <StyledSectionDivider>Anchor properties</StyledSectionDivider>
      <StyledAnchorList>
        {data.anchorData.map(item => (
          <AnchorItem listing_type={item?.listingType} items={items(item)} />
        ))}
      </StyledAnchorList>
    </StyledWrapper>
  );
};

export default Anchor;
