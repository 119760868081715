import styled from 'styled-components';

export const Label = styled.span`
  ${({ color }) => `
    font-size: 8px;
    text-transform: uppercase;
    color: ${color || '#B6BBC6'};
    font-weight: 500;
  `}
`;

export const LabelTitle = styled.span`
  ${({ color }) => ` 
  font-size: 8px;
  height: 12px;
  line-height: 8px;
  color: ${color || '#212632'};
  font-weight: 700;
  display: flex;
  align-items: center;
  `}
`;

export const Comments = styled.div`
  font-size: 8px;
  padding: 8px;
  color: #212632;
  background-color: #eff2f7;
  border-radius: 8px;
`;

export const BoxValue = styled.div`
  ${({ width, background }) => `
    width: ${width || '100%'};
    background-color: ${background || '#EFF2F7'};
    font-size: 10px;
    font-weight: 700;
    height: 19px;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `}
`;
