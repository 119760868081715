import styled from 'styled-components';
import Tag from '../../../Tag/Tag';
import { tagColor } from '../../utils/tagColor';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
`;
const StyledText = styled.span`
  font-size: 8px;
`;
const StyledTag = styled(Tag)`
  font-size: 8px;
  width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
`;

const MainFeatures = ({ data }) => {
  const { buildingQuality, lift, swimmingPool } = data;

  return (
    <StyledWrapper>
      <StyledTag
        background={tagColor(buildingQuality)?.background}
        color={tagColor(buildingQuality)?.color}
        rounded
      >
        <StyledText>Building quality: {buildingQuality}</StyledText>
      </StyledTag>
      {lift && (
        <StyledTag background="primary100" color="primary500" rounded>
          <StyledText>Lift</StyledText>
        </StyledTag>
      )}
      {swimmingPool && (
        <StyledTag background="primary100" color="primary500" rounded>
          <StyledText>Pool</StyledText>
        </StyledTag>
      )}
    </StyledWrapper>
  );
};

export default MainFeatures;
