import styled, { css } from 'styled-components';
import { Label } from '../../CommonStyledComponents';
import { IconMapPin } from '@uda/bento-components';
import _ from "lodash"

const StyledWrapper = styled.div`
  width: 100%;
`;
const StyledData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;
const StyledValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 148px;
`;
const StyledLeyend = styled.div`
  display: flex;
  justify-content: space-between;
  color: #747d93;
  font-size: 10px;
`;
const StyledValue = styled.span`
  ${({ isSelectedValue }) => css`
    height: 23px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${isSelectedValue ? '#D7ECEA' : '#eff2f7'};
    color: ${isSelectedValue ? '#1B817A' : '#B6BBC6'};
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
  `}
`;

const LocationQuality = ({ data }) => {
  const { macroLocation, microLocation } = data;

  const location = [
    {
      label: 'Macro Location',
      value: macroLocation
    },
    {
      label: 'Micro Location',
      value: microLocation
    }
  ];
  const renderValues = (selectedValue, activateLeyend) => {
    const values = _.range(1, 5);
    return (
      <StyledValues>
        <div style={{ display: 'flex', gap: '4px' }}>
          {values.map(value => (
            <StyledValue isSelectedValue={value === selectedValue}>{value}</StyledValue>
          ))}
        </div>
        {activateLeyend && (
          <StyledLeyend>
            <span>worst</span>
            <span>best</span>
          </StyledLeyend>
        )}
      </StyledValues>
    );
  };
  return (
    <StyledWrapper>
      <Label>
        <IconMapPin size="small" />
        Location Quality
      </Label>
      {location.map(({ label, value }, index) => (
        <StyledData>
          <Label>{label}</Label>
          {renderValues(value, index === location.length - 1)}
        </StyledData>
      ))}
    </StyledWrapper>
  );
};

export default LocationQuality;
