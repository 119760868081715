import styled from "styled-components";
import Divider from "../../Divider";
import Image from "../../Image";
import LocationComments from "./LocationComments";
import LocationQuality from "./LocationQuality";
import DevUnitsAreas from "./DevUnitsAreas/DevUnitsAreas";
import { useContext } from "react";

const StyledWrapper = styled.div`
    width: 100%;
`;
const StyledImagesContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 16px;
`;
const StyledMapUnitsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    gap: 24px;
`;

const StyledDevUnitsAreas = styled(DevUnitsAreas)`
    width: 374px;
    height: 372px;
`;

const StyledDataContainer = styled.div`
    display: grid;
    grid-template-columns: auto 705px;
    grid-template-rows: 164px;
    column-gap: 32px;
`;

const Location = ({ className, data }) => {
    const {
        imageCoordinates,
        locationQuality,
        locationComments,
        developmentUnits,
    } = data;
    const [latitude, longitude] = imageCoordinates || [];

    //Map types
    const mapNormalStyle = "udarealestate/ckoskrsre4iro17n4rr0qlsrq";
    const mapSateliteStyle = "udarealestate/cl2zv90a6003i14p6ls6woa52";

    //Central marker
    const centralMarker = `pin-l-circle+307bdf(${longitude},${latitude})`;

    //Mapbox
    const urlMap = `https://api.mapbox.com/styles/v1/${mapSateliteStyle}/static/${centralMarker}/${longitude},${latitude},16/663x372?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
    const linkMap = `https://maps.google.com/?q=${latitude},${longitude}`;
    //Street view
    const urlStreetView = `https://maps.googleapis.com/maps/api/streetview?size=337x295&location=${latitude},${longitude}&source=outdoor&pitch=5&fov=130&return_error_codes=true&key=${process.env.REACT_APP_STREET_VIEW_API_KEY}`;

    const locationImages = [
        {
            url: `https://api.mapbox.com/styles/v1/${mapNormalStyle}/static/${centralMarker}/${longitude},${latitude},4/337x295?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
            tag: "Macro location",
            link: `https://maps.google.com/?q=${latitude},${longitude}`,
        },
        {
            url: `https://api.mapbox.com/styles/v1/${mapNormalStyle}/static/${centralMarker}/${longitude},${latitude},11/337x295?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
            tag: "Micro Location",
            link: `https://maps.google.com/?q=${latitude},${longitude}`,
        },
        {
            url: urlStreetView,
            tag: "Street / Façade Cadastre",
        },
    ];

    return (
        <StyledWrapper className={className}>
            <Divider>Location</Divider>
            <StyledImagesContainer>
                {imageCoordinates &&
                    locationImages.map(({ url, tag, link }) => (
                        <a href={link} target="_blank">
                            <Image
                                label={tag}
                                src={url}
                                width="337px"
                                height="295px"
                            />
                        </a>
                    ))}
            </StyledImagesContainer>
            <StyledMapUnitsContainer>
                {imageCoordinates && (
                    <a href={linkMap} target="_blank">
                        <Image src={urlMap} width="663px" height="372px" />
                    </a>
                )}
                <StyledDevUnitsAreas
                    data={developmentUnits}
                    fullTables={false}
                />
            </StyledMapUnitsContainer>
            <StyledDataContainer>
                <LocationQuality data={locationQuality} />
                <LocationComments data={locationComments} />
            </StyledDataContainer>
        </StyledWrapper>
    );
};

export default Location;
