import styled from 'styled-components';
import { IconInfo } from '@uda/bento-components';
import { Label } from '../../CommonStyledComponents';

const StyledDescription = styled.p`
  width: 1081px;
  font-size: 9px;
  margin-top: 4px;
`;

const Description = ({ children, className, data }) => {
  return (
    <div>
      <Label color="#dcdfe6">
        <IconInfo size="small" />
        Valuation Comments
      </Label>
      <StyledDescription>{data?.comment || 'No comments'}</StyledDescription>
    </div>
  );
};

export default Description;
