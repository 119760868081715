import styled from 'styled-components';
import {
  Label,
  BoxValue,
  LabelTitle
} from '../../../CommonStyledComponents';
import { IconBedroom } from '@uda/bento-components';

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const StyledRooms = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledRoom = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const StyledIconBedroom = styled(IconBedroom)`
  width: 14px;
  margin-right: 4px;
`;
const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;
const StyledTags = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Rooms = ({ items }) => {
  return (
    <StyledRooms>
      <StyledTitle>
        <Label color="#747D93">Residential</Label>
        <StyledTags>
          <LabelTitle color="#B6BBC6">#Rooms</LabelTitle>
          <LabelTitle color="#B6BBC6">#Assets</LabelTitle>
        </StyledTags>
      </StyledTitle>
      <StyledContent>
        {items &&
          Object.entries(items)?.map(([label, value]) => (
            <StyledRoom>
              <Label color="#747D93">
                <StyledIconBedroom size="small" customColor="#747D93" />
                {label}
              </Label>
              <BoxValue background="#FAFBFC" width="35px">
                {value || '-'}
              </BoxValue>
            </StyledRoom>
          ))}
      </StyledContent>
    </StyledRooms>
  );
};

export default Rooms;
