import styled from 'styled-components';
import Table from './Table';

const StyledTable = styled(Table)`
  ${({ height, width }) => `
  height: ${height || '504px'};
  width: ${width || '1073px'};
  overflow: hidden;
`}

  .MuiDataGrid-columnHeaders {
    height: 23.5px !important;
    max-height: 23.5px !important;
    min-height: 23.5px !important;
    font-size: 8px !important;
  }

  .MuiDataGrid-row {
    font-size: 8px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    padding: 0 10px !important;
    font-weight: 700 !important;
    text-overflow: unset !important;
    overflow: unset !important;
    white-space: unset !important;
  }
`;

const AssetTable = ({ rows, columns, height, width }) => {
  return (
    <StyledTable
      getRowClassName={null}
      height={height}
      width={width}
      rowHeight={24}
      rows={rows}
      columns={columns}
    />
  );
};

export default AssetTable;
