import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  height: 19px;
  padding: 1px 18px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.charcoal600};
  color: ${({ theme }) => theme.color.white};
  font-weight: 700;
  font-size: 10px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
`;

const Divider = ({ children, className }) => {
  return <StyledWrapper className={className}>{children}</StyledWrapper>;
};

export default Divider;
