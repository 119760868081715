import {
  IconNewHome,
  IconElevator,
  IconExterior,
  IconSwimmingPool,
  IconParking,
  IconStorage,
  IconTerrace,
  IconAirConditioning,
  IconBathroom,
  IconSecurity,
  IconKitchen,
  IconFacade,
  IconHeating,
  IconShowcase,
  IconChimmey,
  IconCorner,
  IconNew,
  IconLoadingDock,
  IconTime,
  IconAutomaticDoor
} from '@uda/bento-components';

export const getCharacteristicsIcons = txt => {
  switch (txt) {
    case 'has_heating':
      return <IconHeating size="small" />;
    case 'has_storefront':
      return <IconShowcase size="small" />;
    case 'has_facade':
      return <IconFacade size="small" />;
    case 'has_smoke_outlet':
      return <IconChimmey size="small" />;
    case 'has_equipped_kitchen':
      return <IconKitchen size="small" />;
    case 'is_on_the_corner':
      return <IconCorner size="small" />;
    case 'has_automatic_door':
      return <IconAutomaticDoor size="small" />;
    case 'has_security':
      return <IconSecurity size="small" />;
    case 'has_access_24h':
      return <IconTime size="small" />;
    case 'has_loading_dock':
      return <IconLoadingDock size="small" />;
    case 'new_home':
      return <IconNewHome size="small" />;
    case 'is_new':
      return <IconNew size="small" />;
    case 'elevator':
    case 'has_elevator':
      return <IconElevator size="small" />;
    case 'swimming_pool':
    case 'has_pool':
      return <IconSwimmingPool size="small" />;
    case 'is_exterior':
      return <IconExterior size="small" />;
    case 'has_garage':
    case 'is_covered':
      return <IconParking size="small" />;
    case 'has_storage':
    case 'has_warehouse':
      return <IconStorage size="small" />;
    case 'terrace':
    case 'has_terrace':
      return <IconTerrace size="small" />;
    case 'air_conditioning':
    case 'has_air_conditioner':
      return <IconAirConditioning size="small" />;
    case 'has_bathroom':
      return <IconBathroom size="small" />;
    default:
      console.warn(`Unknown feature: ${txt}`);
      return null;
  }
};
