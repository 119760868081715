import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  padding: 4px 90px 4px 16px;
  border-radius: 8px;
  background-color: ${({ theme, divType }) =>
    divType === 'Sale' ? theme.color.aubergine200 : theme.color.carrot200};
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  margin-right: 16px;
`;

const StyledValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 7px;
`;

const StyledTitle = styled.p`
  color: ${({ theme, divType }) =>
    divType === 'Sale' ? theme.color.aubergine700 : theme.color.carrot700};
  width: 24px;
  height: 18px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
  margin: 0;
  text-align: center;
`;

const StyledSubtitle = styled.p`
  color: ${({ theme, divType }) =>
    divType === 'Sale' ? theme.color.aubergine700 : theme.color.carrot700};
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
const StyledRange = styled.p`
  color: ${({ theme, divType }) =>
    divType === 'Sale' ? theme.color.aubergine500 : theme.color.carrot500};
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
`;

const CustomDivider = ({ children, title, values, divType }) => {
  return (
    <>
      <StyledWrapper divType={divType}>
        <StyledTitle divType={divType}>{title}</StyledTitle>
        <StyledInfo>
          {values.map(value => (
            <StyledValue>
              <StyledSubtitle divType={divType}>{value.title}</StyledSubtitle>
              <StyledSubtitle divType={divType}>{value.value}</StyledSubtitle>
              <StyledRange divType={divType}>{value.range}</StyledRange>
            </StyledValue>
          ))}
        </StyledInfo>
      </StyledWrapper>
      {children}
    </>
  );
};

export default CustomDivider;
