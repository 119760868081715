import React from 'react';
import { GridRow } from '@mui/x-data-grid';
import { IconAnchor } from '@uda/bento-components';
import Tag from '../../../Tag/Tag';
import styled from 'styled-components';
import formatNumber from '../../utils/formatNumber';
import { getCharacteristicsIcons } from '../../utils/getCharacteristicsIcons';
import { stateColor } from '../../utils/stateColor';

const StyledTag = styled(Tag)`
  font-size: 8px;
  width: fit-content;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const useSaleRentTableConfig = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 28,
      maxWidth: 28,
      minWidth: 28,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.id ? <>{row.id}</> : <>-</>;
      }
    },
    {
      field: 'adStatus',
      headerName: 'Ad Status',
      width: 77,
      maxWidth: 77,
      minWidth: 77,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.adStatus ? (
          <StyledTag
            color={stateColor(row.adStatus)?.color}
            background={stateColor(row.adStatus)?.background}
            rounded
          >
            {row.adStatus}
          </StyledTag>
        ) : (
          <>-</>
        );
      }
    },
    {
      field: 'typology',
      headerName: 'Typology',
      width: 74,
      maxWidth: 74,
      minWidth: 74,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.typology ? <>{row.typology}</> : <>-</>;
      }
    },
    {
      field: 'area',
      headerName: 'Area (sqm)',
      width: 56,
      maxWidth: 56,
      minWidth: 56,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.area ? <>{row.area}</> : <>-</>;
      }
    },
    {
      field: 'price',
      headerName: 'Price (sqm)',
      width: 58,
      maxWidth: 58,
      minWidth: 58,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.price ? <>{formatNumber(row.price)}</> : <>-</>;
      }
    },
    {
      field: 'priceM2',
      headerName: 'Price (€/sqm)',
      width: 66,
      maxWidth: 66,
      minWidth: 66,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.priceM2 ? <>{formatNumber(row.priceM2)}</> : <>-</>;
      }
    },
    {
      field: 'dateOut',
      headerName: 'Date Out',
      width: 75,
      maxWidth: 75,
      minWidth: 75,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.dateOut ? <>{row.dateOut}</> : <>-</>;
      }
    },
    {
      field: 'yeaeBuilt',
      headerName: 'Y.Built',
      width: 43,
      maxWidth: 43,
      minWidth: 43,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.yearBuilt ? <>{row.yearBuilt}</> : <>-</>;
      }
    },
    {
      field: 'floor',
      headerName: 'Floor',
      width: 39,
      maxWidth: 39,
      minWidth: 39,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.floor ? <>{row.floor}</> : <>-</>;
      }
    },
    {
      field: 'rooms',
      headerName: 'Rooms',
      width: 46,
      maxWidth: 46,
      minWidth: 46,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.rooms ? <>{row.rooms}</> : <>-</>;
      }
    },
    {
      field: 'characteristics',
      headerName: 'Attributes',
      width: 76,
      maxWidth: 76,
      minWidth: 76,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'left',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.characteristics ? (
          row?.characteristics.map(characteristic => <>{getCharacteristicsIcons(characteristic)}</>)
        ) : (
          <>-</>
        );
      }
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 342,
      maxWidth: 342,
      minWidth: 342,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.address ? <>{row.address}</> : <>-</>;
      }
    },
    {
      field: 'distance',
      headerName: 'Distance',
      width: 52,
      maxWidth: 52,
      minWidth: 52,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'right',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.distance ? <>{row.distance}</> : <>-</>;
      }
    },
    {
      field: 'tom',
      headerName: 'TOM',
      width: 39,
      maxWidth: 39,
      minWidth: 39,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.tom ? <>{row.tom}</> : <>-</>;
      }
    },

    {
      field: 'link',
      headerName: 'Link',
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.link ? <a href={row.link}>link</a> : <>-</>;
      }
    }
  ];

  const memoizedRow = React.memo(GridRow);

  return { columns, memoizedRow };
};
