export function openStreetView({ lat, lon }) {
  window.open(`http://maps.google.com/maps?q=&layer=c&cbll=${lat},${lon}`, '_blank');
}

export function openCadastreLink({ active, reference, lat, lon }) {
  let isRefcat = active && reference && reference !== '';
  if (isRefcat) {
    const formatedReference = reference.replace(/\s/g, '');

    let rc1 = formatedReference.substring(0, 7);
    let rc2 = formatedReference.substring(7, 14);

    return window.open(
      `https://www1.sedecatastro.gob.es/cycbieninmueble/OVCListaBienes.aspx?rc1=${rc1}&rc2=${rc2}`,
      '_blank'
    );
  } else
    return window.open(
      `https://www1.sedecatastro.gob.es/cycbieninmueble/OVCListaBienes.aspx?del=8&muni=72&rc1=2783405&rc2=DF2728D&from=OVCBusqueda&final=&pest=coordenadas&latitud=${lat}&longitud=${lon}&gradoslat=&minlat=&seglat=&gradoslon=&minlon=&seglon=&x=&y=&huso=0&tipoCoordenadas=2&TipUR=Coor`,
      '_blank'
    );
}

export function openCadastreMapLink({ active, reference }) {
  let isRefcat = active && reference && reference !== '';
  if (!isRefcat) return;

  const formatedReference = reference.replace(/\s/g, '');

  return window.open(
    `https://www1.sedecatastro.gob.es/Cartografia/mapa.aspx?refcat=${formatedReference}`,
    '_blank'
  );
}

export function formatCadastreLink(reference) {
  let rc1 = reference?.substring(0, 7);
  let rc2 = reference?.substring(7, 14);

  return `https://www1.sedecatastro.gob.es/cycbieninmueble/OVCListaBienes.aspx?rc1=${rc1}&rc2=${rc2}`;
}
