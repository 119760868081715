import React from 'react';
import { GridRow } from '@mui/x-data-grid';
import styled from 'styled-components';

const StyledHeaderText = styled.span`
  font-size: 8px;
  line-height: 8px;
  font-weight: 700;
  text-align: center;
`;
const StyledHeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const useAreasTableConfig = () => {
  const columns = [
    {
      field: 'area',
      headerName: 'Area(sqm',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Area</StyledHeaderText>
          <StyledHeaderText>(sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.areaSum ? <>{row.areaSum.toFixed(2)}</> : <>-</>;
      }
    },
    {
      field: 'units',
      headerName: '# Units',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText># Units</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.units ? <>{row.units}</> : <>-</>;
      }
    },
    {
      field: 'units_anexed',
      headerName: '#Units Annexed',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>#Units</StyledHeaderText>
          <StyledHeaderText>Annexed</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.unitsAnexed ? <>{row.unitsAnexed}</> : <>-</>;
      }
    },
    {
      field: 'avg_sqm_unit',
      headerName: 'Avg. Sqm/unit',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Avg.</StyledHeaderText>
          <StyledHeaderText>Sqm/unit</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.avg ? <>{row.avg.toFixed(1)}</> : <>-</>;
      }
    },
    {
      field: 'plot_area',
      headerName: 'Plot Area (sqm)',
      renderHeader: () => (
        <StyledHeaderBlock>
          <StyledHeaderText>Plot Area</StyledHeaderText>
          <StyledHeaderText>(sqm)</StyledHeaderText>
        </StyledHeaderBlock>
      ),
      width: 48,
      maxWidth: 48,
      minWidth: 48,
      height: 32,
      maxHeight: 32,
      minHeight: 32,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.plotSum ? <>{row.plotSum}</> : <>-</>;
      }
    }
  ];

  const memoizedRow = React.memo(GridRow);

  return { columns, memoizedRow };
};
