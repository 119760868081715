import styled from 'styled-components';
import { Label } from '../../CommonStyledComponents';
import formatNumber from '../../utils/formatNumber';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;
const StyledPrice = styled.span`
  font-size: 32px;
  color: #fff;
  margin-bottom: 19px;
  margin-top: 4px;
`;
const StyledPriceSqm = styled.span`
  font-size: 12px;
  color: #dcdfe6;
`;

const MarketValue = ({ data }) => {
  const { marketValue, sqm, sqmLand } = data;
  return (
    <StyledWrapper>
      <Label color="#fff">Market value</Label>
      <StyledPrice>{formatNumber(marketValue, 0)}€</StyledPrice>
      <StyledPriceSqm>{formatNumber(sqm)} €/sqm</StyledPriceSqm>
      <StyledPriceSqm>{formatNumber(sqmLand)} €/sqm land</StyledPriceSqm>
    </StyledWrapper>
  );
};

export default MarketValue;
