import styled from 'styled-components';
import Divider from '../../Divider';
import Table from '../../Table';
import Leyend from './Leyend';
import { useAreasTableConfig } from './useAreasTableConfig';
import { useMarketTableConfig } from './useMarketTableConfig';
import { useAdoptedValuesTableConfig } from './useAdoptedValuesTableConfig';
import { useSellersTableConfig } from './useSellersTableConfig';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin-top: -9px;
`;
const StyledLeyend = styled(Leyend)`
  padding-top: 20px;
  margin-top: 25px;
`;

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders {
    height: 23.5px !important;
    max-height: 23.5px !important;
    min-height: 23.5px !important;
    font-size: 8px !important;
  }
  .MuiDataGrid-row {
    font-size: 8px !important;
  }
`;

const DevelopmentUnits = ({ className, data }) => {
  const { areasAndUnits, marketComparables, adoptedValues, sellersAppraisal } = data;
  const { columns: areasColumns } = useAreasTableConfig();
  const { columns: marketColumns } = useMarketTableConfig();
  const { columns: adoptedColumns } = useAdoptedValuesTableConfig();
  const { columns: sellersColumns } = useSellersTableConfig();
  const items = [
    'Multi-family Housing',
    'Garage',
    'Storage',
    'Terraced House',
    'Detached House',
    'Commercial',
    'Office',
    'Industrial',
    'Other',
    'Building',
    'Land',
    'Subtotal'
  ];
  return (
    <div className={className}>
      <Divider>Development units</Divider>
      <StyledWrapper>
        <StyledLeyend items={items} />
        <StyledTable
          rows={areasAndUnits}
          columns={areasColumns}
          rowHeight={19}
          title="Areas & Units"
        />
        <StyledTable
          rows={marketComparables}
          columns={marketColumns}
          rowHeight={19}
          title="Market comparable (avg.)"
        />
        <StyledTable
          rows={adoptedValues}
          columns={adoptedColumns}
          rowHeight={19}
          title="Adopted values"
        />
        <StyledTable
          rows={sellersAppraisal}
          columns={sellersColumns}
          rowHeight={19}
          title="Seller’s Appraisal"
        />
      </StyledWrapper>
    </div>
  );
};

export default DevelopmentUnits;
