import styled, { css } from 'styled-components';

const StyledTableContainer = styled.div`
  ${({ height, width }) => css`
    font-family: Roboto, Arial, sans-serif;
    width: fit-content;
    .last-row {
      background-color: #eff2f7;
    }
    height: ${height}px;
    width: ${width}px;
  `}
`;
const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTableHeader = styled.div`
  height: 32px;
  font-size: 14px;
  display: flex;
  background-color: #fafbfc;
`;
const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTableHeaderCell = styled.div`
  ${({ column }) => css`
    width: ${column.width}px;
    font-size: 9px;
    font-weight: 700;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
const StyledTableRowCell = styled.div`
  ${({ column }) => css`
    width: ${column.width}px;
    max-width: ${column.maxWidth}px;
    overflow: hidden;
    font-size: 8px;
    border-top: 1px solid #dcdfe6;
    display: flex;
    padding: 4px;
    align-items: center;
  `}
`;
const StyledTableHeaderContent = styled.div`
  ${({ column }) => css`
    text-align: ${column.headerAlign || 'center'};
    width: 100%;
  `}
`;
const StyledTableRowContent = styled.div`
  ${({ column }) => css`
    text-align: ${column.headerAlign || 'center'};
    width: 100%;
  `}
`;
const StyledTableRow = styled.div`
  ${({ rowHeight }) => css`
    display: flex;
    height: ${rowHeight}px;
  `}
`;
const StyledWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;
const StyledTitle = styled.span`
  font-size: 8px;
  line-height: 12px;
  color: #747d93;
`;

const ReportTable = ({
  title,
  columns,
  rows,
  className,
  rowHeight = 24,
  height,
  width,
  getRowClassName = (rows, i) => i === rows.length - 1 && 'last-row',
  ...props
}) => {
  return (
    <StyledWrapper>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledTableContainer className={className}>
        <StyledTable>
          <StyledTableHeader>
            {columns.map(column => (
              <StyledTableHeaderCell column={column} key={column.field}>
                <StyledTableHeaderContent column={column}>
                  {column.renderHeader ? column.renderHeader() : column.headerName}
                </StyledTableHeaderContent>
              </StyledTableHeaderCell>
            ))}
          </StyledTableHeader>
          <StyledTableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index} rowHeight={rowHeight}>
                {columns.map(column => (
                  <StyledTableRowCell
                    className={getRowClassName && getRowClassName(rows, index)}
                    column={column}
                    key={column.field}
                  >
                    <StyledTableRowContent column={column}>
                      {column.renderCell({ row })}
                    </StyledTableRowContent>
                  </StyledTableRowCell>
                ))}
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </StyledWrapper>
  );
};

export default ReportTable;
